import { type } from "@testing-library/user-event/dist/type";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import Select from "react-select";
import ApiConfig from "../api/ApiConfig";
import {
  multipartPostCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
} from "../api/ApiServices";
	
	   
import circle_cross from "../assets/images/circle_cross.svg";
import { AppContext } from "../context/user/AppContext";
import DragAndDropForVehicles from "../sharedComponent/DragAndDropForVehicles";
import DragAndDropForVehiclesInsurance from "../sharedComponent/DragAndDropForVehiclesInsurance";

const AddVehicles = ({ logedIn }) => {
  const navigate = useNavigate();
  const allowedFileTypes = ["application/pdf", "image/png", "image/jpeg"];
  let unique = [];
								 
  const params = useParams();
  let id = params.id;
																		  
  const [container, setContainer] = useState([]);
  const [selectedBreakBulk, setSelectedBreakBulk] = useState(false);
  const [selectedBulk, setSelectedBulk] = useState(false);
  const [containerNames, setContainerNames] = useState([{}]);
  const [carQuantity, setCarQuantity] = useState([1,2,3,4,5,6,7,8,9,10]);
  const [otherCompany, setOtherCompany] = useState("");												   
					 
				  
  const [loading, setLoading] = useState(false);
					   
			 
  const [dropDownList, setDropDownList] = useState({
    vehicle_name: [],
    vehicle_insurance_type: [],
    vehicle_axle: [],
    vehicle_insurance_type: [],
	  insurance_list: [],				   
	
  
   
		   
	   
		  
  });
  const [vehiclesDetails, setVehicleDetails] = useState({
    vehicle_images: [],
				 
    plate_no: "",
    vehicle_name: "",
    vehicle_axle: "",
    vehicle_type:"",
    vehicle_container_type:"",
    chassis_no: "",
    gross_weight: "",
    initial_km: "",
    model: "",
    insurance_no: "",
    year_manufacture: "",
    motor_no: "",
    capacity: "",
    vendor_name: "",
    vendor_contact: "",
    vendor_platform: "",
    vendor_address: "",
    insurance_file: null,
    insurance_issue_date: null,
    insurance_expiry_date: "",
    insurance_company: "",
    insurance_type: "",
    sum_insured: "",
  });


	  useEffect(() => {
    if (vehiclesDetails.insurance_company != 19) {
        setOtherCompany("");
    }    
  }, [otherCompany]);			   			 

					  
													 
								   
																					
		  
						

  const [errMsg, setErrMsg] = useState({
    // vehicle_images: "",
    // owner_id: "",
    plate_no: "",
    vehicle_name: "",
    vehicle_axle: "",
    vehicle_type: "",
    vehicle_container_type: "",
    chassis_no: "",
    gross_weight: "",
    // initial_km: "",
    // model: "",
    // year_manufacture: "",
    // motor_no: "",
    //capacity: "",
    // vendor_name: "",
    // vendor_contact: "",
    // vendor_platform: "",
    // vendor_address: "",
    insurance_file: "",
    insurance_issue_date: "",
    insurance_expiry_date: "",
    insurance_company: "",
    insurance_type: "",
    sum_insured: "",
  });

		 
  const [ownerList, setOwnerList] = useState([]);
  const { mainClass, customerData, ApiCaaling, setApiCalling, tp_id } =
    useContext(AppContext);
  
  const Add = () => {
    if (
      // !vehiclesDetails.vehicle_images.length ||
      // vehiclesDetails.owner_id === "" ||
      vehiclesDetails.plate_no === "" ||
      vehiclesDetails.vehicle_name === "" ||
											  
      vehiclesDetails.chassis_no === "" ||
      vehiclesDetails.gross_weight === "" ||
      // vehiclesDetails.initial_km === "" ||
      // vehiclesDetails.model === "" ||
      // vehiclesDetails.year_manufacture === "" ||
      // vehiclesDetails.motor_no === "" ||
      //vehiclesDetails.capacity === "" ||
      // vehiclesDetails.vehicle_name === "" ||
      // vehiclesDetails.vendor_contact === "" ||
      // vehiclesDetails.vendor_platform === "" ||
      // vehiclesDetails.vendor_address === "" ||
      !vehiclesDetails.insurance_file ||
      vehiclesDetails.insurance_issue_date === "" ||
      vehiclesDetails.insurance_expiry_date === "" ||
      vehiclesDetails.insurance_no === "" ||
      vehiclesDetails.insurance_company === "" ||
      vehiclesDetails.insurance_type === "" ||
      vehiclesDetails.sum_insured === ""
    ) {
      // if (!vehiclesDetails.vehicle_images.length) {
      //   setErrMsg({ ...errMsg, vehicle_images: "Vehicle Images are required" });
      //   window.scrollTo(0, 0);
      //   return;
      // }
      // if (vehiclesDetails.owner_id === "") {
      //   setErrMsg({ ...errMsg, owner_id: "Select Qwner from list" });
      //   window.scrollTo(0, 0)
      //   return
      // }
			
				   
		  
	   
	 
      if (vehiclesDetails.plate_no === "") {
        setErrMsg({ ...errMsg, plate_no: "Plate No is required" });
        window.scrollTo(0, 100);
        return;
      }
      if (vehiclesDetails.vehicle_name === "") {
        setErrMsg({ ...errMsg, vehicle_name: "Vehicle Type is required" });
        window.scrollTo(0, 100);
        return;
      }
      if (vehiclesDetails.chassis_no === "") {
        setErrMsg({ ...errMsg, chassis_no: "Chasis Number is required" });
        window.scrollTo(0, 200);
        return;
      }
      if (vehiclesDetails.gross_weight === "") {
        setErrMsg({ ...errMsg, gross_weight: "Gross Weight is required" });
        window.scrollTo(0, 200);
        return;
      }
      // if (vehiclesDetails.initial_km === "") {
      //   setErrMsg({ ...errMsg, initial_km: "Initial Km is required" });
      //   window.scrollTo(0, 400);
      //   return;
      // }
      // if (vehiclesDetails.model === "") {
      //   setErrMsg({ ...errMsg, model: "Model is required" });
      //   window.scrollTo(0, 400);
      //   return;
      // }
      // if (vehiclesDetails.year_manufacture === "") {
      //   setErrMsg({
      //     ...errMsg,
      //     year_manufacture: "Yesr Of Manufacture is required",
      //   });
      //   window.scrollTo(0, 600);
      //   return;
      // }
      // if (vehiclesDetails.motor_no === "") {
      //   setErrMsg({ ...errMsg, motor_no: "Moter No is required" });
      //   window.scrollTo(0, 600);
      //   return;
      // }
      // if (vehiclesDetails.capacity === "") {
      //   setErrMsg({ ...errMsg, capacity: "Vehicle Capacity is required" });
      //   window.scrollTo(0, 800);
      //   return;
      // }
      // if (vehiclesDetails.vendor_name === "") {
      //   setErrMsg({ ...errMsg, vendor_name: "Gps Vendor Name is required" });
      //   window.scrollTo(0, 800);
      //   return;
      // }
      // if (vehiclesDetails.vendor_contact === "") {
      //   setErrMsg({
      //     ...errMsg,
      //     vendor_contact: "Gps Vendor Contact is required",
      //   });
      //   window.scrollTo(0, 1000);
      //   return;
      // }
      // if (vehiclesDetails.vendor_platform === "") {
      //   setErrMsg({
      //     ...errMsg,
      //     vendor_platform: "Gps Vendor Platform is required",
      //   });
      //   window.scrollTo(0, 1000);
      //   return;
      // }
      // if (vehiclesDetails.vendor_address === "") {
      //   setErrMsg({
      //     ...errMsg,
      //     vendor_address: "Gps Vendor Address is required",
      //   });
      //   window.scrollTo(0, 1200);
      //   return;
      // }
      if (
        !vehiclesDetails.insurance_file &&
        !vehiclesDetails.insurance_file.length
      ) {
        setErrMsg({ ...errMsg, insurance_file: "Insurance File is required" });
        window.scrollTo(0, 1200);
        return;
      }
      if (vehiclesDetails.insurance_issue_date === "") {
        setErrMsg({
          ...errMsg,
          insurance_issue_date: "Insurance Issue date is required",
        });
        window.scrollTo(0, 1400);
        return;
      }
      if (vehiclesDetails.insurance_expiry_date === "") {
        setErrMsg({
          ...errMsg,
          insurance_expiry_date: "Insurance Expiry date is required",
        });
        window.scrollTo(0, 1400);
        return;
      }
      if (vehiclesDetails.insurance_no === "") {
        setErrMsg({ ...errMsg, insurance_no: "Insurance no is required" });
        window.scrollTo(0, 1400);
        return;
      }
      if (vehiclesDetails.insurance_company === "") {
        setErrMsg({
          ...errMsg,
          insurance_company: "Insurance Company is required",
        });
        window.scrollTo(0, 1400);
        return;
      }
      if (vehiclesDetails.insurance_type === "") {
        setErrMsg({ ...errMsg, insurance_type: "Insurance Type is required" });
        window.scrollTo(0, 1400);
        return;
      }
      if (vehiclesDetails.sum_insured === "") {
        setErrMsg({ ...errMsg, sum_insured: "Sum Insured is required" });
        window.scrollTo(0, 1400);
        return;
      }
    } else {
      setApiCalling(true);
      let formData = new FormData();
      // formData.append("api_key", customerData.api_key);
      // formData.append("user_id", customerData.user_id);
      // formData.append("customer_id", customerData.customer_id);
      formData.append("owner_id", tp_id);
		let insurance_company_name = "";
      dropDownList.insurance_list.filter(e=> { 

          if (e.insurance_id == vehiclesDetails.insurance_company && vehiclesDetails.insurance_company != 19) {
            
            insurance_company_name = e.insurance_name;
            return true;
          }
          
        })
        .map(f => {
            return f.insurance_name;
        });														   
      formData.append("plate_no", vehiclesDetails.plate_no);
      formData.append("chassis_no", vehiclesDetails.chassis_no);
      formData.append("model", vehiclesDetails.model);
      formData.append("year_manufacture", vehiclesDetails.year_manufacture);
      formData.append("motor_no", vehiclesDetails.motor_no);
      formData.append("gross_weight", vehiclesDetails.gross_weight);
      formData.append("initial_km", vehiclesDetails.initial_km);
      formData.append("insurance_no", vehiclesDetails.insurance_no);
      formData.append("capacity", vehiclesDetails.capacity);
      formData.append("vehicle_name", vehiclesDetails.vehicle_name);
      formData.append("vehicle_axle", vehiclesDetails.vehicle_name === "5" || (vehiclesDetails.vehicle_name === "4" &&
        vehiclesDetails.vehicle_type === "5") ? vehiclesDetails.vehicle_axle : "");
  
      formData.append("vehicle_container_type", JSON.stringify(container));
      formData.append("vehicle_bulk", selectedBulk);
      formData.append("vehicle_breakBulk", selectedBreakBulk);
      formData.append("vehicle_type", vehiclesDetails.vehicle_name === "4" ? vehiclesDetails.vehicle_type : "");
      formData.append(
        "insurance_issue_date",
        vehiclesDetails.insurance_issue_date
      );
      formData.append(
        "insurance_expiry_date",
        vehiclesDetails.insurance_expiry_date
      );
      formData.append("insurance_company", 
          insurance_company_name !== "" ? insurance_company_name : vehiclesDetails.insurance_company == 19 ? 
          otherCompany : vehiclesDetails.insurance_company);
      formData.append("insurance_type", vehiclesDetails.insurance_type);
      formData.append("sum_insured", vehiclesDetails.sum_insured);
      formData.append("vendor_name", vehiclesDetails.vendor_name);
      formData.append("vendor_address", vehiclesDetails.vendor_address);
      formData.append("vendor_contact", vehiclesDetails.vendor_contact);
      formData.append("vendor_platform", vehiclesDetails.vendor_platform);
      //   formData.append("owner_id", customerData.user_id);
      formData.append("insurance_file", vehiclesDetails.insurance_file);
      vehiclesDetails?.vehicle_images?.map((img) => {
        formData.append("vehicle_images[]", img);
      });

      id && formData.append("vehicle_id", id);

      multipartPostCallWithErrorResponse(
        ApiConfig.REGISTER_ADD_VEHICLE,
        formData
      )
        .then((res) => {
          if (res.json.message === 
            "Invalid user authentication,Please try to relogin with exact credentials.") {
              localStorage.clear();
              window.open("/login", "_self");
          }
							   
          if (res.json.result) {
									  
						  
            // swal({
            //     title: "Vehicle Registered Successfully!",
            //     text: "Kindly Wait for Approval",
            //     icon: "success",
            //     dangerMode: false,
            //     button: true,
            //   })

														  
												 
								
								  
							  
				 
            navigate("/RegisteredVehiclesList");
					
									 
			 
          }else {
		   
	 
	  
	   
            swal({
              text: res.json.message,
              icon: "warning",
              dangerMode: false,
              button: false,
            });
          }
          setApiCalling(false);
        })
        .catch((err) => {
          setApiCalling(false);
          console.log(err);
          navigate("/ServerError")
        });
    }
  };
  useEffect(() => {
    if (loading) document.getElementById("continue-btn").disabled = true;
  }, [loading]);

  useEffect(() => {
    if (logedIn) {
      getTransporterList();
      getVehicleDetails();
    }
    getDropDownList();
	   
  
  }, []);
  const getVehicleDetails = () => {
    setApiCalling(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.VEHICLE_DETAILS,
      JSON.stringify({ ...customerData, vehicle_id: id })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setApiCalling(false);
		
        if (res.json.result) {
          let vehicle_Details = res.json.vehicle_details;
          let driverDetails = res.json.driver_details;
		  
			 
          setVehicleDetails({
            vehicle_images: vehicle_Details.images,
            // owner_id: customerData.user_id,
            plate_no: vehicle_Details.plate_number,
            vehicle_name: vehicle_Details.vehicle_name_id,
            vehicle_axle: vehicle_Details.vehicle_axle_id, 
            vehicle_type: vehicle_Details.vehicle_name_id, 
            //vehicle_container_type: vehicle_Details.vehicle_container_id, 
            chassis_no: vehicle_Details.vehicle_chassis_no,
            gross_weight: vehicle_Details.vehicle_gross_weight,
            initial_km: vehicle_Details.vehicle_initial_km,
            model: vehicle_Details.vehicle_model_no,
            year_manufacture: vehicle_Details.year_manufacture,
            motor_no: vehicle_Details.vehicle_motor_no,
            capacity: vehicle_Details.vehicle_capacity,
            vendor_name: vehicle_Details.vehicle_vendor_name,
            insurance_no: vehicle_Details.insurance_no,
            vendor_contact: vehicle_Details.vehicle_vendor_contact,
            vendor_platform: vehicle_Details.vehicle_vendor_platform,
            vendor_address: vehicle_Details.vehicle_vendor_address,
            insurance_file: vehicle_Details.vehicle_insurance_image,
            insurance_issue_date: vehicle_Details.vehicle_insurance_issue_date,
            insurance_expiry_date: vehicle_Details.vehicle_insurance_expiry,
            insurance_company: vehicle_Details.vehicle_insurance_company,
            insurance_type:
              dropDownList.vehicle_insurance_type &&
              dropDownList.vehicle_insurance_type.filter(
                (type) =>
                  type.vehicle_insurance_type_value ===
                  vehicle_Details.insurance_type
              ).length &&
              dropDownList.vehicle_insurance_type.filter(
                (type) =>
                  type.vehicle_insurance_type_value ===
                  vehicle_Details.insurance_type
              )[0].vehicle_insurance_type_id,
            vehicle_images: vehicle_Details.images,
            sum_insured: vehicle_Details.vehicle_sum_insured,
            insurance_no: vehicle_Details.vehicle_insurance_no,
          });
        }
      })
      .catch((err) => {
        setApiCalling(false);
        console.log(err);
        navigate("/ServerError");
      });
  };
  const getDropDownList = () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.VEHICLE_DROPDOWNLIST,
      JSON.stringify({ ...customerData })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      if (res.json.result) {
		
        setDropDownList(res.json); 
        let containers = []; 
        res.json.vehicle_container.map(e=> {
          containers.push({label: e.container_type_name, value: e.container_type_id});
        })
        setContainerNames(containers);
      }
    }).catch((err) => {
      navigate("/ServerError");
    });
  };

  const getTransporterList = () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.TRANSPORTER_LIST,
      JSON.stringify({ ...customerData })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
				
      if (res.json.result) {
 
        setOwnerList(res.json.transporter_list);
      }
    });
  };
  const removeVehicleImage = (index) => {
    let images = vehiclesDetails.vehicle_images;
    images = images.filter((img) => images.indexOf(img) != index);
    setVehicleDetails({ ...vehiclesDetails, vehicle_images: images });
  };

  const handleCheckboxBreakBulkChange = (event) => {
    if (event.target.checked) {
      setSelectedBreakBulk(true);
    } else {
      setSelectedBreakBulk(false);
    }
  };

  const handleCheckboxBulkChange = (event) => {
    if (event.target.checked) {
      setSelectedBulk(true);
    } else {
      setSelectedBulk(false);
    }
  };

  return (
    <>
      {/* <Sidebar/>
         <AbhayHeader/> */}
	  
		
	  
      <main
        className={
          logedIn
            ? `main-section-dashboard scroll-style ${mainClass} `
            : `main-section-dashboard scroll-style `
        }
        id="cxMain"
      >
        <div className="main-body-AddVehicle">
          {/* Vehicle Info Add */}
          <section className="main-vehicle-info mt-4">
            <div className="content">
              <div className="row">
                <div className="col-12">
                  <h5 className="card-heading ">Vehicle Info</h5>
                  <hr />
                  <div className="row mt-3">
                    <div className="col-lg-6">
                      <div className="top-item mt-3">
                        <label className="" style={{ color: "#66739B" }}>
                          Upload Vehicle Images
                        </label>
                        <DragAndDropForVehicles
                          setFile={(files) => {
				 
                            setErrMsg({ ...errMsg, vehicle_images: "" });
                            setVehicleDetails({
                              ...vehiclesDetails,
                              vehicle_images: [
                                ...vehiclesDetails.vehicle_images,
                                ...files,
                              ],
                            });
                            if (
                              !allowedFileTypes.includes(files[0].type) ||
                              files[0].size > 5242880
                            ) {
                              swal({
                                icon: "error",
                                title: "Invalid file type",
                                text: "Please upload a file of type PDF, PNG, or JPEG and should not exceed more than 5MB.",
                              });
                              setVehicleDetails({
                                ...vehiclesDetails,
                                vehicle_images: [],
                              });
                            }
								   
                          }}
                        />
                        {/* {errMsg.vehicle_images.length > 0 && (
                          <div className="text-center text-danger">
                            <span> {errMsg.vehicle_images}</span>
                          </div>
                        )} */}
                      </div>
                      <div className="uploaded_items d-flex mt-4">
                        {vehiclesDetails?.vehicle_images?.map((img, index) => {
                          return (
                            <div
                              className="item_list"
                              key={"vehicleImages" + index}
                            >
                              <img
                                width={72}
                                height="72"
                                className="vehicle_img"
                                src={
                                  img.name
                                    ? URL.createObjectURL(img)
                                    : ApiConfig.BASE_URL_FOR_IMAGES +
                                      img.vehicle_image_url
                                }
                                alt=""
                              />
                              <a href="#" className="close_item">
                                <img
                                  src={circle_cross}
                                  alt=""
                                  onClick={() => removeVehicleImage(index)}
                                />
                              </a>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="row">
					  
				  
			
		   
		 
			 
		  
				   
			  
				
			   
			  
					 
			  
				
				  
		  
		  
		
				
				   
				   
		   
		
		  
				   
					 
		
		  
				
				   
				
			  
				   
			  
				
				
		  
		  
		  
				
				  
			
		 
		 
			 
			 
				 
				 
		  
				
			
			 
				 
				 
		  
				
			
		 
		   
			
		 
 
				 
				   
				 
		   
		
		   
                        <div className="form-group mt-3 col-md-6 ">
                          <label htmlFor="exampleInputEmail1">
                            Plate Number
                          </label>
                          <input
                            type="text"
                            className="form-control "
                            value={vehiclesDetails.plate_no}
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            onChange={(e) => {
                              setErrMsg({ ...errMsg, plate_no: "" });
                              setVehicleDetails({
                                ...vehiclesDetails,
                                plate_no: e.target.value,
                              });
                            }}
                          />
                          {errMsg.plate_no.length > 0 && (
                            <div className="text-center text-danger">
                              <span> {errMsg.plate_no}</span>
                            </div>
                          )}
                        </div>
                        <div className="form-group mt-3 col-md-6">
                          <label htmlFor="exampleInputEmail1">
                            Vehicle Type
                          </label>
                          <select
                            value={vehiclesDetails.vehicle_name}
                            className="form-select"
                            aria-label="Default select example"
                            onChange={(e) => {
                              setErrMsg({ ...errMsg, vehicle_name: "" });
                              setVehicleDetails({
                                ...vehiclesDetails,
                                vehicle_name: e.target.value,
                              });
                            }}
                          >
                            <option>Select Vehicle Type</option>
                            {dropDownList.vehicle_name.map((vehicle, index) => {
                              return (
                                <option
                                  value={vehicle.vehicle_name_id}
                                  key={"vehicle" + index}
                                >
                                  {vehicle.vehicle_name_value} {' '}
                                  {(vehicle.vehicle_name_id !== 6 && vehicle.vehicle_name_id !== 3
                                      && vehicle.vehicle_name_id != 4
                                  )? 
                                  " Power" : ""}
                                </option>
                              );
                            })}
                          </select>
                          {errMsg.vehicle_name.length > 0 && (
                            <div className="text-center text-danger">
                              <span> {errMsg.vehicle_name}</span>
                            </div>
                          )}
                        </div>

                        {vehiclesDetails.vehicle_name && 
                          (vehiclesDetails.vehicle_name==="4") ?
                        <div className="form-group mt-3 col-md-6">
                          <label htmlFor="exampleInputEmail1">
                            Trailer Vehicle Type
                          </label>
                          <select
                            value={vehiclesDetails.vehicle_type}
                            className="form-select"
                            aria-label="Default select example"
                            onChange={(e) => {
                              setErrMsg({ ...errMsg, vehicle_type: "" });
                              setVehicleDetails({
                                ...vehiclesDetails,
                                vehicle_type: e.target.value,
                              });
                            }}
                          >
                            <option>Select Trailer Vehicle Type</option>
                            
                            {dropDownList.master_vehicle_cargo.filter(e => 
                              (e.cargo_vehicle_trailer_id === 4
                              )).filter(f => {
                                
                                unique.push(f.cargo_vehicle_vehicle_name_id);
                                let count = 0;
                                
                                unique.forEach(g => {if (g == f.cargo_vehicle_vehicle_name_id) {
                                    
                                      count++;
                                      
                                }
                                
                              });
                              if (count > 1) {
                                return false;
                              } else {
                                return true;
                              }
                              count = 0;
                              }).map((vehicle, index) => {
                              return (
                                <option
                                  value={vehicle.vehicle_name_id}
                                  key={"trailer_vehicle_type" + index}
                                >
                                  {vehicle.vehicle_name_value} {' '}
                                  {(vehicle.vehicle_name_id !== 6)? 
		  
                                  " Power" : ""}
                                </option>
                              );
                            })}
                          </select>
                          {/* {errMsg.vehicle_type.length > 0 && (
                            <div className="text-center text-danger">
                              <span> {errMsg.vehicle_type}</span>
                            </div>
                          )} */}
                        </div>:<></>}

                        {vehiclesDetails.vehicle_name && 
                          (vehiclesDetails.vehicle_name==="5" ||
                          (vehiclesDetails.vehicle_name==="4" &&
                          vehiclesDetails.vehicle_type==="5")) ? <div className="form-group mt-3 col-md-6">
																	
                          <label htmlFor="exampleInputEmail1">
                            Vehicle Axle
                          </label>
                          <select
                          value={vehiclesDetails.vehicle_axle}
                          className="form-select"
                          aria-label="Default select example"
                          onChange={(e) => {
                            setErrMsg({ ...errMsg, vehicle_axle: "" });
							
                            setVehicleDetails({
                              ...vehiclesDetails,
                              vehicle_axle: e.target.value,
                            });
                          }}
                        >
                          <option>Select Vehicle Axle</option>
                          {dropDownList.vehicle_axle.map((vehicle, index) => {
                            return (
                              <option
                                value={vehicle.vehicle_axle_id}
                                key={"vehicle_axle" + index}
                              >
                                {vehicle.vehicle_axle_type}
                              </option>
                            );
                          })}
                        </select>
                          {/* {errMsg.vehicle_axle.length > 0 && (
                            <div className="text-center text-danger">
                              <span> {errMsg.vehicle_axle}</span>
                            </div>
                          )} */}
                        </div> : <></>}

                        <div className="form-group mt-3 col-md-6">
                          <label htmlFor="exampleInputEmail1">
                            Chassis Number
                          </label>
                          <input
                            type="text"
                            value={vehiclesDetails.chassis_no}
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            onChange={(e) => {
                              setErrMsg({ ...errMsg, chassis_no: "" });
                              setVehicleDetails({
                                ...vehiclesDetails,
                                chassis_no: e.target.value,
                              });
                            }}
                          />
                          {errMsg.chassis_no.length > 0 && (
                            <div className="text-center text-danger">
                              <span> {errMsg.chassis_no}</span>
                            </div>
                          )}
                        </div>
                        <div className="form-group mt-3 col-md-6">
                          <label htmlFor="exampleInputEmail1">
                            Gross Weight
                          </label>
                          <input
                            value={vehiclesDetails.gross_weight}
                            type="number"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            onChange={(e) => {
                              setErrMsg({ ...errMsg, gross_weight: "" });
                              setVehicleDetails({
                                ...vehiclesDetails,
                                gross_weight: e.target.value,
                              });
                            }}
                          />
                          {errMsg.gross_weight.length > 0 && (
                            <div className="text-center text-danger">
                              <span> {errMsg.gross_weight}</span>
                            </div>
                          )}
                        </div>
                        <div className="form-group mt-3 col-md-6">
                          <label htmlFor="exampleInputEmail1">Model</label>
                          <input
                            type="year"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            value={vehiclesDetails.model}
                            onChange={(e) => {
                              setErrMsg({ ...errMsg, model: "" });
                              setVehicleDetails({
                                ...vehiclesDetails,
                                model: e.target.value,
                              });
                            }}
                          />
                          {/* {errMsg.model.length > 0 && (
                                <div className="text-center text-danger">
                                  <span> {errMsg.model}</span>
                                </div>
                              )} */}
                        </div>
                        <div className="form-group mt-3 col-md-6">
					
                          <label htmlFor="exampleInputEmail1">
                            Year of Manufacture
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            value={vehiclesDetails.year_manufacture}
                            onChange={(e) => {
			 
			
                              setErrMsg({
                                ...errMsg,
                                year_manufacture: "",
                              });
                              setVehicleDetails({
                                ...vehiclesDetails,
                                year_manufacture: e.target.value,
                              });
                            }}
                          />
                          {/* {errMsg.year_manufacture.length > 0 && (
                                <div className="text-center text-danger">
                                  <span> {errMsg.year_manufacture}</span>
                                </div>
                              )} */}
                        </div>
	   
                        {vehiclesDetails && vehiclesDetails.vehicle_name != 4 && (
                          <>
                            <div className="form-group mt-3 col-md-6">
                              <label htmlFor="exampleInputEmail1">
                                Initial Km
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                value={vehiclesDetails.initial_km}
                                onChange={(e) => {
                                  setErrMsg({ ...errMsg, initial_km: "" });
                                  setVehicleDetails({
                                    ...vehiclesDetails,
                                    initial_km: e.target.value,
                                  });
                                }}
                              />
                              {/* {errMsg.initial_km.length > 0 && (
                                <div className="text-center text-danger">
                                  <span> {errMsg.initial_km}</span>
                                </div>
                              )} */}
                            </div>

                            <div className="form-group mt-3 col-md-6">
                              <label htmlFor="exampleInputEmail1">
                                Motor Number
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                value={vehiclesDetails.motor_no}
                                onChange={(e) => {
                                  setErrMsg({ ...errMsg, motor_no: "" });
                                  setVehicleDetails({
                                    ...vehiclesDetails,
                                    motor_no: e.target.value,
                                  });
                                }}
                              />
                              {/* {errMsg.motor_no.length > 0 && (
                                <div className="text-center text-danger">
                                  <span> {errMsg.motor_no}</span>
                                </div>
                              )} */}
                            </div>
                          </>
                        )}

                        {vehiclesDetails.vehicle_name && 
                          vehiclesDetails.vehicle_name ==="3" ||
                          ((vehiclesDetails.vehicle_name ==="1" ||
                          vehiclesDetails.vehicle_name ==="3" || 
                          vehiclesDetails.vehicle_name ==="4") &&
																  
																   
																
																
                          vehiclesDetails.vehicle_type !== "6") ? <div className="form-group mt-3 col-md-6">
                          <label htmlFor="exampleInputEmail1">
                            Vehicle Load Capacity in Quintal
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            value={vehiclesDetails.capacity}
                            onChange={(e) => {
                              setErrMsg({ ...errMsg, capacity: "" });
                              setVehicleDetails({
                                ...vehiclesDetails,
                                capacity: e.target.value,
                              });
                            }}
                          />
                          {/* {errMsg.capacity.length > 0 && (
                            <div className="text-center text-danger">
                              <span> {errMsg.capacity}</span>
                            </div>
                          )} */}
                         </div> : <></>}
                        {vehiclesDetails && (vehiclesDetails.vehicle_name === "4" &&
                          ((vehiclesDetails.vehicle_axle ==='2'
                          && vehiclesDetails.vehicle_type==="5") || 
                          (vehiclesDetails.vehicle_axle ==='1'
                          && vehiclesDetails.vehicle_type==="5"))
                          )
                           ?
                           <div className="form-group mt-3 col-md-12">
                           <label htmlFor="exampleInputEmail1">
                             Container
                           </label>
                           <Select 
                           value={container}
                           //className="form-select"
                           isMulti
                           options={containerNames.filter(e => e.value != 9)}
                            // .filter(e=> e.value === 2 
                            //   || e.value===4 || e.value===6 || e.value===8
                              
                            
                           //aria-label="Default select example"
                           onChange={(container) => {
                                setContainer(container || []);
                              }}
                            >
                         </Select>
                         </div>: <></>}
                         {vehiclesDetails && (vehiclesDetails.vehicle_name === "4" &&
                          (vehiclesDetails.vehicle_type==="2")
                          )
                           ?
                           <div className="form-group mt-3 col-md-12">
                           <label htmlFor="exampleInputEmail1">
                             Container
                           </label>
                           <Select 
                           value={container}
                           //className="form-select"
                           isMulti
                           options={containerNames.filter(e => e.value === 2 || e.value === 4
                              || e.value === 6 || e.value === 8 || e.value === 9
                           )}
                            // .filter(e=> e.value === 2 
                            //   || e.value===4 || e.value===6 || e.value===8
                              
                            
                           //aria-label="Default select example"
                           onChange={(container) => {
                                setContainer(container || []);
                              }}
                            >
                         </Select>
                         </div>: <></>}
                         {vehiclesDetails && (((vehiclesDetails.vehicle_name==="3") ||
                          (vehiclesDetails.vehicle_name==="1")||
                          (vehiclesDetails.vehicle_type==="1" &&
                             vehiclesDetails.vehicle_name==="4"
                             ))
                          )
                           ?
                           <div className="form-group mt-3 col-md-12">
                           <label htmlFor="exampleInputEmail1">
                             Container
                           </label>
                           <Select 
                           value={container}
                           //className="form-select"
                           isMulti
                           options={containerNames.filter(e=> e.value === 1 
                            || e.value===3 || e.value===5 || e.value===7)}
                           //aria-label="Default select example"
                           onChange={(container) => {
                                setContainer(container || []);
                              }}
                            >
                         </Select>
                         </div>: <></>}
                      
                      {vehiclesDetails && vehiclesDetails.vehicle_name==="1"||
                      (vehiclesDetails.vehicle_type==="1" &&
                          vehiclesDetails.vehicle_name==="4") ||
                      (vehiclesDetails.vehicle_type==="3" &&
                          vehiclesDetails.vehicle_name==="4") ||
                          ((vehiclesDetails.vehicle_name==="3") ||
                       (vehiclesDetails.vehicle_name === "4" &&
                          ((vehiclesDetails.vehicle_type==="5" 
                          && vehiclesDetails.vehicle_axle==="2") ||
                          (vehiclesDetails.vehicle_type==="5" 
                          && vehiclesDetails.vehicle_axle==="1") ||
                          (vehiclesDetails.vehicle_type==="2"))
                          )) ?
                        <div className="form-check pt-2 ms-2">
                        <label htmlFor="exampleInputEmail1">
                            Break Bulk
                          </label>
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={'break_bulk'+vehiclesDetails.vehicle_axle}
                          onChange={handleCheckboxBreakBulkChange}
                          checked={selectedBreakBulk}
                        />
                      </div>: <></>}
                      {vehiclesDetails && vehiclesDetails.vehicle_name==="1" || 
                      (vehiclesDetails.vehicle_type==="1" &&
                          vehiclesDetails.vehicle_name==="4") || 
                      (vehiclesDetails.vehicle_type==="3" &&
                          vehiclesDetails.vehicle_name==="4") || 
                          (vehiclesDetails.vehicle_name==="3")
                       || (vehiclesDetails.vehicle_name ==='4'
                          && (vehiclesDetails.vehicle_type==="5"&& 
                            vehiclesDetails.vehicle_axle==="2")) ?
                        <div className="form-check pt-2 ms-2">
                        <label htmlFor="exampleInputEmail1">
                            Bulk
                          </label>
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={'double-axle-bulk'+vehiclesDetails.vehicle_axle}
                          onChange={handleCheckboxBulkChange}
                          checked={selectedBulk}
                        />
                      </div>: <></>}
                      {vehiclesDetails.vehicle_name && 
                          (vehiclesDetails.vehicle_name==="4" &&
                          (vehiclesDetails.vehicle_type==="6")) ? 
                          <div className="form-group mt-3 col-md-6">
                          <label htmlFor="exampleInputEmail1">
                            Car Career Qty
                          </label>
                          <select
                          value={vehiclesDetails.capacity}
                          className="form-select"
                          aria-label="Default select example"
                          onChange={(e) => {
                            setErrMsg({ ...errMsg, capacity: "" });
                            setVehicleDetails({
                              ...vehiclesDetails,
                              capacity: e.target.value,
                            });
                          }}
                        >
                          <option>Select Car Qty.</option>
                          {carQuantity.map((vehicle, index) => {
                            return (
                              <option
                                value={vehicle}
                                key={"vehicle_quantity" + index}
                              >
                                {vehicle}
                              </option>
                            );
                          })}
                        </select>
                          {/* {errMsg.vehicle_axle.length > 0 && (
                            <div className="text-center text-danger">
                              <span> {errMsg.vehicle_axle}</span>
                            </div>
                          )} */}
                        </div> : <></>} 
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* GPS Availibility */}

          <section
            className="main-vehicle-info  mt-3
          "
          >
            <div className="content">
              <div className="row">
                <div className="col-12">
                  <h5 className="card-heading">GPS Availability</h5>
                  <hr />

                  <div className="row">
                    <div className="col-lg-3 col-md-6">
                      <div className="form-group mt-3">
                        <label htmlFor="exampleInputEmail1">Vendor Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          value={vehiclesDetails.vendor_name}
                          onChange={(e) => {
                            setErrMsg({ ...errMsg, vendor_name: "" });
                            setVehicleDetails({
                              ...vehiclesDetails,
                              vendor_name: e.target.value,
                            });
                          }}
                        />
                        {/* {errMsg.vendor_name.length > 0 && (
                          <div className="text-center text-danger">
                            <span> {errMsg.vendor_name}</span>
                          </div>
                        )} */}
                      </div>
		  
		
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="form-group mt-3">
                        <label htmlFor="exampleInputEmail1">
                          Vendor Contact
                        </label>
                        <input
                          type="tel"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          value={vehiclesDetails.vendor_contact}
                          onChange={(e) => {
													
                            const numValue = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                            setErrMsg({ ...errMsg, vendor_contact: "" });
                            setVehicleDetails({
                              ...vehiclesDetails,
                              vendor_contact: numValue,
                            });
							 
                          }}
                        />
                        {/* {errMsg.vendor_contact.length > 0 && (
                          <div className="text-center text-danger">
                            <span> {errMsg.vendor_contact}</span>
                          </div>
                        )} */}
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="form-group mt-3">
                        <label htmlFor="exampleInputEmail1">
                          Vendor Platform
                        </label>
                        <input
                          type="tel"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          value={vehiclesDetails.vendor_platform}
                          onChange={(e) => {
                            setErrMsg({ ...errMsg, vendor_platform: "" });
                            setVehicleDetails({
                              ...vehiclesDetails,
                              vendor_platform: e.target.value,
                            });
                          }}
                        />
                        {/* {errMsg.vendor_platform.length > 0 && (
                          <div className="text-center text-danger">
                            <span> {errMsg.vendor_platform}</span>
                          </div>
                        )} */}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group mt-3">
                        <label htmlFor="exampleFormControlTextarea1">
                          Vendor Address
                        </label>
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                          value={vehiclesDetails.vendor_address}
                          onChange={(e) => {
                            setErrMsg({ ...errMsg, vendor_address: "" });
                            setVehicleDetails({
                              ...vehiclesDetails,
                              vendor_address: e.target.value,
                            });
                          }}
                        ></textarea>
                        {/* {errMsg.vendor_address.length > 0 && (
                          <div className="text-center text-danger">
                            <span> {errMsg.vendor_address}</span>
                          </div>
                        )} */}
                      </div>
		  
                    </div>
                  </div>
                </div>
              </div>
            </div>
	   
          </section>

          {/* Vehicles Documents */}
          <section
            className="main-vehicle-info mt-4"
            style={{ marginBottom: "80px" }}
          >
            <div className="content">
              <div className="row">
                <div className="col-12">
                  <h5 className="card-heading">Vehicles Documents</h5>
                  <hr />
                  <div className="row ">
                    <div className="col-lg-6 ">
                      <div className="top-item mt-3">
                        <label style={{ color: "#66739B" }}>
                          Upload Insurance
                        </label>
                        <DragAndDropForVehiclesInsurance
                          setFile={(files) => {
   
                            setErrMsg({ ...errMsg, insurance_file: "" });
                            setVehicleDetails({
                              ...vehiclesDetails,
                              insurance_file: files,
                            });
                            if (
                              !allowedFileTypes.includes(files.type) ||
                              files.size > 5242880
                            ) {
                              swal({
                                icon: "error",
                                title: "Invalid file type",
                                text: "Please upload a file of type PDF, PNG, or JPEG and should not exceed more than 5MB.",
                              });
                              setVehicleDetails({
                                ...vehiclesDetails,
                                insurance_file: "",
                              });
                            }
                          }}
		 
                          insuranceFile={vehiclesDetails.insurance_file}
                        />
                        {errMsg.insurance_file.length > 0 && (
                          <div className="text-center text-danger">
                            <span> {errMsg.insurance_file}</span>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="row">
                        <div className="form-group mt-3 col-md-6">
                          <label htmlFor="exampleInputEmail1">
                            Insurance No
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            value={vehiclesDetails.insurance_no}
                            onChange={(e) => {
                              setErrMsg({
                                ...errMsg,
                                insurance_issue_date: "",
                              });
                              setVehicleDetails({
                                ...vehiclesDetails,
                                insurance_no: e.target.value,
                              });
                            }}
                          />
                          {errMsg.insurance_issue_date.length > 0 && (
                            <div className="text-center text-danger">
                              <span> {errMsg.insurance_issue_date}</span>
                            </div>
                          )}
                        </div>
                        <div className="form-group mt-3 col-md-6">
                          <label htmlFor="exampleInputEmail1">
                            Insurance Issue Date
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            value={vehiclesDetails.insurance_issue_date}
                            onChange={(e) => {
                              setErrMsg({
                                ...errMsg,
                                insurance_issue_date: "",
                              });
                              setVehicleDetails({
                                ...vehiclesDetails,
                                insurance_issue_date: e.target.value,
                              });
                            }}
                          />
                          {errMsg.insurance_issue_date.length > 0 && (
                            <div className="text-center text-danger">
                              <span> {errMsg.insurance_issue_date}</span>
                            </div>
                          )}
                        </div>
                        <div className="form-group mt-3 col-md-6">
                          <label htmlFor="exampleInputEmail1">
                            Insurance Expiry Date
                          </label>
                          <input
                            type="date"
                            value={vehiclesDetails.insurance_expiry_date}
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            onChange={(e) => {
                              setErrMsg({
                                ...errMsg,
                                insurance_expiry_date: "",
                              });
                              setVehicleDetails({
                                ...vehiclesDetails,
                                insurance_expiry_date: e.target.value,
                              });
                            }}
                          />
                          {errMsg.insurance_expiry_date.length > 0 && (
                            <div className="text-center text-danger">
                              <span> {errMsg.insurance_expiry_date}</span>
                            </div>
                          )}
                        </div>
                        <div className="form-group mt-3 col-md-6">
                          <label htmlFor="exampleInputEmail1">
                            Insurance Company
                          </label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            value={vehiclesDetails.insurance_company}
													
												   
														
                            onChange={(e) => {
                              setErrMsg({ ...errMsg, insurance_company: "" });
                              setVehicleDetails({
                                ...vehiclesDetails,
                                insurance_company: e.target.value,
                              });
                            }}
                          >
                            <option>Select Insurance Company</option>
                            {dropDownList.insurance_list.map(
                              (type, index) => {
                                return (
                                  <option
                                    value={type.insurance_id}
                                    key={"type" + index}
                                  >
                                    {type.insurance_name}
                                  </option>
                                );
                              }
                            )}
                          </select>
                          {errMsg.insurance_company.length > 0 && (
                            <div className="text-center text-danger">
                              <span> {errMsg.insurance_company}</span>
                            </div>
                          )}
                        </div>
                        
                        {vehiclesDetails.insurance_company == 19 && (
                  <div className="col-lg-12">
                  <div className="form-group mt-4">
                    <label htmlFor="exampleInputEmail1">
                      Specify Insurance Company
                    </label>
                    <input
                      type="text"
                      value={otherCompany}
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        setErrMsg({ ...errMsg, insurance_company: "" });
                        setOtherCompany(e.target.value);
				
				   
		  
                      }}
                    />
                    {errMsg.insurance_company.length > 0 && (
                      <div className="text-center text-danger">
                        <span> {errMsg.insurance_company}</span>
                      </div>
                    )}
                  </div>
	
                </div>
                )}
                        <div className="form-group mt-3 col-md-6">
                          <label htmlFor="exampleInputEmail1">
                            Type of Insurance
                          </label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            value={vehiclesDetails.insurance_type}
                            onChange={(e) => {
                              setErrMsg({ ...errMsg, insurance_type: "" });
                              setVehicleDetails({
                                ...vehiclesDetails,
                                insurance_type: e.target.value,
                              });
                            }}
                          >
                            <option>Select Insurance Type</option>
                            {dropDownList.vehicle_insurance_type.map(
                              (type, index) => {
                                return (
                                  <option
                                    value={type.vehicle_insurance_type_id}
                                    key={"type" + index}
                                  >
                                    {type.vehicle_insurance_type_value}
                                  </option>
                                );
                              }
                            )}
                          </select>
                          {errMsg.insurance_type.length > 0 && (
                            <div className="text-center text-danger">
                              <span> {errMsg.insurance_type}</span>
                            </div>
                          )}
                        </div>
                        <div className="form-group mt-3 col-md-6">
                          <label htmlFor="exampleInputEmail1">
                            Sum Insured
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            value={vehiclesDetails.sum_insured}
                            onChange={(e) => {
                              setErrMsg({ ...errMsg, sum_insured: "" });
                              setVehicleDetails({
                                ...vehiclesDetails,
                                sum_insured: e.target.value,
                              });
                            }}
                          />
                          {errMsg.sum_insured.length > 0 && (
                            <div className="text-center text-danger">
                              <span> {errMsg.sum_insured}</span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Vehicles owner Detail */}
        </div>
        <div className="fix-bottom-bar d-flex justify-content-between align-items-center mt-4">
          <div className="left"></div>
          <div className="right">
            <a href="">
              <button type="submit" className="cancel-btn">
                Cancel
              </button>
            </a>
            {/* <Link to="/VehicleDriverDetailsHeader" > */}
            <button
              type="submit"
              className="continue-btn"
              onClick={Add}
              id="continue-btn"
            >
              Continue
            </button>
            {/* </Link> */}
            {/* <div className="login-btn">
                <button
                  type="submit"
                  
                  className="btn  col-sm-12 col-md-12 col-lg-12"
                >
                  <span style={{ color: "white" }}> Continue</span>
                </button>
              </div> */}
          </div>
        </div>
      </main>
    </>
  );
};

export default AddVehicles;
