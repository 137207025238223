import React, {useEffect, useContext} from 'react';
import { Link } from "react-router-dom";
import { AppContext } from '../context/user/AppContext';
const ServerError = () => {
    const { mainClass, setState } = useContext(AppContext);

    function logout() {
        localStorage.clear();
        sessionStorage.clear();
        let transporterData = {};
        setState({...transporterData});
        
    }
    useEffect(() => {
        logout();
      }, []);
    return (
        <>
        <main className={`main-body-Notification ${mainClass}`} id="cxMain">
                <h3>Server Error</h3>
                <Link to="/" >
                    <h6>Go to Login</h6>
                </Link>
                </main>

        </>
    
    );
}
export default ServerError;