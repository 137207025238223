import React, { useContext, useEffect, useState } from "react";
import document_image from "../../assets/images/vehiclesDetails/document_image.png";
import ic_options_menu from "../../assets/images/ic_options_menu.svg";
import { useLocation } from "react-router-dom";
import {
  PostCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/user/AppContext";
import Loader from "../../sharedComponent/Loader";
import swal from "sweetalert";
import ProgressBar from "../../sharedComponent/ProgressBar";

const DriverDetails = () => {
  let driver = useLocation().state;
  const [loading, setLoading] = useState(false);
  const [progress, setprogress] = useState(0);
  let { customerData, mainClass } = useContext(AppContext);
  let [driverDetails, setDriverDetails] = useState({});

  useEffect(() => {
    setLoading(true);
    PostCallWithErrorResponse(ApiConfig.DRIVER_DETAILS, {
      ...customerData,
      driver_id: driver.id,
    })
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setLoading(false);
        if (res.json.result) {
          setDriverDetails(res.json.driver_details);
        }
      })
      .catch((err) => console.log(err));
  }, []);
  const blockDriver = () => {
    setprogress(25);
    setprogress(50);
    postWithAuthCallWithErrorResponse(
      ApiConfig.BLOCK_DRIVER,
      JSON.stringify({ ...customerData, driver_id: driver.driver_id })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setprogress(100);
        if (res.json.result) {
          swal(res.json.message);
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      {/* // <VehicleDriverDetailsHeader/>  */}

      <main className="main-section-DriverDetails my-3 mx-0" id="cxMain">
        <ProgressBar progress={progress} />
        {loading ? (
          <Loader />
        ) : (
          <div className="main-body-DriverDetails">
            {/* Driver Details */}
            <section className="owner-detail-section common-body p-3 mt-4">
              <div className="row">
                <div className="col-12">
                  <div className="top-content d-flex justify-content-between">
                    <div className="driver-profile-details d-flex align-items-center">
                      <div className="profile-pic me-3">
                        <img
                          src={
                            ApiConfig.BASE_URL_FOR_IMAGES +
                            driverDetails.profile_pic
                          }
                          alt=""
                        />
                      </div>
                      <div className="driver-name">
                        <div className="title-driver">
                          <h4 className="name-driver">
                            {driverDetails.driver_name}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-lg-3 col-md-6 ">
                      <label>Full Name</label>
                      <p className="data">{driverDetails.driver_name}</p>
                    </div>
                    <div className="col-lg-3 col-md-6 ">
                      <label>Email</label>
                      <p className="data">{driverDetails.email}</p>
                    </div>

                    <div className="col-lg-3 col-md-6 ">
                      <label>Mobile Number</label>
                      <p className="data">{driverDetails.mobile_number}</p>
                    </div>
                    <div className="col-lg-3 col-md-6 ">
                      <label>Zone</label>
                      <p className="data">{driverDetails.city}</p>
                    </div>
                    <div className="col-lg-3 col-md-6  ">
                      <label>Woreda</label>
                      <p className="data">{driverDetails.woreda}</p>
                    </div>
                    <div className="col-lg-3 col-md-6  ">
                      <label>House No.</label>
                      <p className="data">{driverDetails.house_no}</p>
                    </div>
                    <div className="col-lg-3 col-md-6 ">
                      <label>P.O Box</label>
                      <p className="data">{driverDetails.po_box}</p>
                    </div>
                    <div className="col-lg-3 col-md-6  ">
                      <label>Birthdate</label>
                      <p className="data">{driverDetails.birthdate}</p>
                    </div>
                    <div className="col-lg-3 col-md-6 ">
                      <label>Gender</label>
                      <p className="data">{driverDetails.gender}</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* Documents dtails */}
            <section className="documents-detail-section common-body p-3 mt-4 mb-4">
              <div className="row">
                <div className="col-12">
                  <div className="top-content">
                    <h5 className="card-heading">Documents</h5>
                    <hr />
                  </div>
                  <div className="row mt-4">
                    <div className="col-lg-2 col-md-6 ">
                      <div className="insurance-copy">
                        <label>Licence Copy</label>
                        <div className="option-menu d-flex align-items-start">
                          <img
                            src={
                              ApiConfig.BASE_URL_FOR_IMAGES +
                                driverDetails.license_document || document_image
                            }
                            alt=""
                            width="132px"
                            height="80px"
                          />
                         
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-6 ">
                      <label>Issue Date</label>
                      <p className="data">{driverDetails.license_issue_date}</p>
                    </div>
                    <div className="col-lg-2 col-md-6 ">
                      <label>Expiry Date</label>
                      <p className="data">
                        {driverDetails.license_expiry_date}
                      </p>
                    </div>
                    <div className="col-lg-2 col-md-6 ">
                      <label>License Grade</label>
                      <p className="data">{driverDetails.license_grade}</p>
                    </div>
                    <div className="col-lg-2 col-md-6 ">
                      <label>License Number</label>
                      <p className="data">{driverDetails.licence_number}</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
      </main>
    </>
  );
};

export default DriverDetails;
