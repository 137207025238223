import React, { useContext, useState } from "react";
import ic_options from "../../assets/images/header/ic_options.svg";
import moment from "moment";
import swal from "sweetalert";
import dropdown_arrow_blue from "../../assets/images/dropdown_arrow_blue.svg";
import { AppContext } from "../../context/user/AppContext";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
const Notifications = () => {
  const { mainClass, notificationList, setNotificationList, customerData } = useContext(AppContext);
  const deleteNotification = (id) => {
    postWithAuthCallWithErrorResponse(ApiConfig.DELETE_NOTIFICATION, JSON.stringify({ ...customerData, notification_ids: [id] }))
      .then(res => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        if (res.json.result) {
          swal(res.json.message, "success")
          setNotificationList(notificationList.filter(notification => notification.notification_id !== id))
        }
      })
  }
  return (
    <>
      {/* <Sidebar/>
         <AbhayHeader/> */}
      <main className={"main-body-Notification  " + mainClass} id="cxMain">
        {notificationList.map((notification, index) => {
          return (
            <section className="common-body notification-block mb-2" key={"notificationroute" + index}>
              <div className="top d-flex justify-content-between ">
                <p className="notifications mb-0">
                  {notification.notification_text}
                </p>
                <div className="option-dropdown dropdown ">
                  <a
                    href=""
                    className=""
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img src={ic_options} alt="" />
                  </a>
                  <ul className="dropdown-menu ">
                    <li>
                      <button className="dropdown-item" type="button" onClick={() => deleteNotification(notification.notification_id)}>
                        Delete <img src={dropdown_arrow_blue} className="orderConArow" alt="" />
                      </button>
                    </li>

                  </ul>
                </div>
              </div>
              <label htmlFor="">{moment(new Date(notification.notification_date + " " + notification.notification_time)).fromNow()}</label>
            </section>
          )
        })}
      </main>
    </>
  );
};

export default Notifications;
