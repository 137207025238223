import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import "../../assets/css/Transportvehicle.scss";
import ic_import from "../../assets/images/Trasnvehicle/ic_import.svg";
import eyes from "../../assets/images/eyes.svg";
import Loader from "../../sharedComponent/Loader";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { AppContext } from "../../context/user/AppContext";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import ProgressBar from "../../sharedComponent/ProgressBar";
import swal from "sweetalert";
import * as XLSX from "xlsx";
const VehicleList = () => {
  const { mainClass } = useContext(AppContext);
  const [vehicle, setVehicle] = useState({});
  const [loading, setLoading] = useState(false);
  const [vehicleList, setVehicleList] = useState([]);
  const { customerData } = useContext(AppContext);
  const [progress, setProgrss] = useState(0);
  $(document).ready(function () {
    let table;
    if ($.fn.dataTable.isDataTable(".dataList")) {
      table = $(".dataList").DataTable();
    } else {
      table = $(".dataList").DataTable({
        ordering: false,

        language: {
          search: "",
          searchPlaceholder: "Search...",
          sLengthMenu: "Entries per page _MENU_",
        },
      });
    }
    // $('#transporterList').DataTable({
    //   // ordering: false
    // });
  });
  const exportToexcel = () => {
    var excelData = [
      [
        "S.No",
        "Plate No.",
        "Model",
        "Vehicle Type",
        "Driver Name",
        "Driver Nationality",
      ],
    ];
    vehicleList.map((elt, index) => {
      excelData.push([
        index + 1,
        elt.plate_number,
        elt.vehicle_model_no,
        elt.vehicle_type,
        elt.driver_name,
        elt.nationality,
      ]);
    });
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.aoa_to_sheet(excelData);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    XLSX.writeFile(wb, "Vehicles_list.xlsx");
  };
  useEffect(() => {
    setLoading(true);
    getVehciles();
    return () => {
      setLoading(false); // This worked for me
      setVehicleList({});
    };
  }, []);
  const blockvehicle = (vehicle) => {
    setProgrss(25);
    setProgrss(50);
    postWithAuthCallWithErrorResponse(
      ApiConfig.BLOCK_VEHICLE,
      JSON.stringify({ ...customerData, vehicle_id: vehicle.vehicle_id })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setProgrss(100);
        if (res.json.result) {
          swal(res.json.message);
        }
      })
      .catch((err) => console.log(err));
  };
  const getVehciles = () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.VEHICLE_LIST,
      JSON.stringify({ ...customerData })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setLoading(false);
        if (res.json.result) {
          setVehicleList(res.json.vehicle_list);
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <div className={"vehicle-list " + mainClass} id="cxMain">
        <div className="row">
          <div className=" ">
            <div className="d-flex  ">
              <div className="input-group   justify-content-end ">
                <div
                  className="icon-group   mt-2"
                  data-bs-toggle="tooltip"
                  data-bs-html="true"
                  title="Export to excel"
                >
                  <ul className="d-flex list-unstyled   ">
                    <li className="nav-item icons p-2 me-3">
                      <Link to="#" onClick={exportToexcel}>
                        <img
                          src={ic_import}
                          className="add"
                          alt="send-load-icon"
                        />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="" style={{ width: "100%", overflow: "auto" }}>
              <ProgressBar progress={progress} />
              {loading ? (
                <>
                  <Loader />
                </>
              ) : (
                <table className="dataList">
                  <thead>
                    <tr>
                      <th>
                        <label>S.No</label>
                      </th>
                      <th>
                        <label>Plate No.</label>
                      </th>
                      <th>
                        <label>Model</label>
                      </th>
                      <th>
                        <label>Vehicle Type</label>
                      </th>
                      {/* <th>
                        <label>Driver Name</label>
                      </th>
                      <th>
                        <label>Driver Nationality</label>
                      </th> */}
                      <th>
                        <label>Status</label>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {vehicleList.length &&
                      vehicleList.map((vehicle, index) => {
                        return (
                          <>
                            <tr
                              className="Rowbgcolor p-2"
                              key={"vehicle" + index}
                            >
                              <td>{vehicle.vehicle_id}</td>
                              <td id="Vehicle">{vehicle.plate_number}</td>
                              <td id="Model">{vehicle.vehicle_model_no}</td>
                              <td id="Plate">{vehicle.vehicle_type}</td>
                              {/* <td id="DriverNAM">
                                <Link
                                  to="/DriverDetails"
                                  state={{ driver_id: vehicle.driver_id }}
                                  className="Vehicl-list-driverName-link"
                                >
                                  {" "}
                                  {vehicle.driver_name}
                                </Link>
                              </td>
                              <td id="Nationality">{vehicle.nationality}</td> */}
                              <td id="Nationality">{vehicle.vehicle_status}</td>

                              <div className="option-dropdown dropdown p-2">
                                <Link
                                  to="/VehiclesDetails"
                                  className="text-decoration-none"
                                  state={vehicle}
                                >
                                  <button
                                    className="dropdown-item "
                                    type="button"
                                  >
                                    <img src={eyes} alt="" />
                                  </button>
                                </Link>
                              </div>
                            </tr>
                          </>
                        );
                      })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VehicleList;
