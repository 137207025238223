import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CompleteFreights from "./CompleteFreights";
import OngoingFreights from "./OngoingFreights";
import UpcomingFreights from "./UpcomingFreights";
import "../../assets/css/Freightsheader.scss";
import { postMultipartWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/user/AppContext";
import Loader from "../../sharedComponent/Loader";

const FreightsHeaders = () => {
  const [ongoingFrights, setOnGoingFrights] = useState([]);
  const [upCommingFrights, setUpCommingFrights] = useState([]);
  const [completedFrights, setUpCompletedFrights] = useState([]);
  const [loading, setLoading] = useState(false);

  const { customerData } = useContext(AppContext);
  const { mainClassB } = useContext(AppContext);
  const getOngoingFrights = () => {
    setLoading(true);
    postMultipartWithAuthCallWithErrorResponse(
      ApiConfig.ONGOING_FRIGHT,
      JSON.stringify({ ...customerData })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setLoading(false);
        setOnGoingFrights(res.json.load_list);
      })
      .catch((err) => console.log(err));
  };
  const getUpcommingFrights = () => {
    setLoading(true);
    postMultipartWithAuthCallWithErrorResponse(
      ApiConfig.UPCOMMING_FRIGHT,
      JSON.stringify({ ...customerData })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setLoading(false);
        setUpCommingFrights(res.json.load_list);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getUpcommingFrights();
    getOngoingFrights();
  }, []);
  return (
    <main>
      <div className={"Freights-body " + mainClassB}>
        <ul
          className="nav nav-pills mb-1"
          id="pills-tab"
          role="tablist"
          style={{ backgroundColor: "#ffff" }}
        >
          <li className="nav-item" role="presentation">
            <Link
              to="/OngoingFreights"
              className="nav-link tab-link active"
              id="pills-OngoingFreights-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-OngoingFreights"
              type="button"
              role="tab"
              aria-controls="pills-OngoingFreights"
              aria-selected=""
            >
              Ongoing Freights
            </Link>
          </li>
          <li className="nav-item " role="presentation">
            <Link
              onClick={() => {
                getUpcommingFrights();
              }}
              to="/UpcomingFreights"
              className="nav-link tab-link "
              id="pills-UpcomingFreights-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-UpcomingFreights"
              type="button"
              role="tab"
              aria-controls="pills-UpcomingFreights"
              aria-selected=""
            >
              Upcoming Freights
            </Link>
          </li>

          <li className="nav-item" role="presentation">
            <Link
              onClick={() => {
                setLoading(true);
                postMultipartWithAuthCallWithErrorResponse(
                  ApiConfig.COMPLETED_FRIGHT,
                  JSON.stringify({ ...customerData })
                )
                  .then((res) => {
                    if (res.json.message === 
                      "Invalid user authentication,Please try to relogin with exact credentials.") {
                        localStorage.clear();
                        window.open("/login", "_self");
                    }
                    setLoading(false);
                    setUpCompletedFrights(res.json.load_list);
                  })
                  .catch((err) => console.log(err));
              }}
              to="/CompleteFreights"
              className="nav-link tab-link"
              id="pills-Completed-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-Completed"
              type="button"
              role="tab"
              aria-controls="pills-Completed"
              aria-selected=""
            >
              Completed
            </Link>
          </li>
        </ul>
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-OngoingFreights"
            role="tabpanel"
            aria-labelledby="pills-OngoingFreights-tab"
          >
            {loading ? (
              <Loader />
            ) : (
              <OngoingFreights
                ongoingFrights={ongoingFrights}
                getOngoingFrights={getOngoingFrights}
              />
            )}
          </div>

          <div
            className="tab-pane fade"
            id="pills-UpcomingFreights"
            role="tabpanel"
            aria-labelledby="pills-UpcomingFreights-tab"
          >
            {loading ? (
              <Loader />
            ) : (
              <UpcomingFreights
                upCommingFrights={upCommingFrights}
                getUpcommingFrights={getUpcommingFrights}
              />
            )}
          </div>
          <div
            className="tab-pane fade"
            id="pills-Completed"
            role="tabpanel"
            aria-labelledby="pills-Completed-tab"
          >
            {loading ? (
              <Loader />
            ) : (
              <CompleteFreights completedFrights={completedFrights} />
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default FreightsHeaders;
