import React, { useContext, useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import remove from "../../assets/images/ic_remove_red.svg";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { AppContext } from "../../context/user/AppContext";
import Loader from "../../sharedComponent/Loader";
import ApiConfig from "../../api/ApiConfig";
import {
  PostCallWithErrorResponse,
  postMultipartWithAuthCallWithErrorResponse,
} from "../../api/ApiServices";
import swal from "sweetalert";

const VehicleRequestOnlineAuction = () => {
  const { mainClass, customerData } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  $(document).ready(function () {
    $(".datalist").DataTable();
  });
  const [show, setShow] = useState(false);
  const [showAccept, setShowAccept] = useState(false);
  const [vehicleRequestList, setVehicleRequestList] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [currentLoadId, setCurrentLoadId] = useState("");
  const [vehicleList, setVehicleList] = useState([]);
  const handleShowAccept = () => setShowAccept(true);
  const handleCloseAccept = () => setShowAccept(false);
  useEffect(() => {
    setLoading(true);
    PostCallWithErrorResponse(ApiConfig.ONLINE_AUCTION_OFFER_GOODS, {
      ...customerData,
    })
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setLoading(false);
        if (!res.error) {
          setVehicleRequestList(res.json.load_list);
        }
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    postMultipartWithAuthCallWithErrorResponse(
      ApiConfig.VIEWSPECIFICATION,
      JSON.stringify({ ...customerData, load_id: currentLoadId })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setLoading(false);
        if (!res.error) {
          setVehicleList(res.json.view_specifications);
        }
      })
      .catch((err) => console.log(err));
  }, [currentLoadId]);
  const reject = (vehicle) => {
    postMultipartWithAuthCallWithErrorResponse(
      ApiConfig.DIRECT_ORDER_OFFER_GOODS_VEHICLE_REJECT,
      JSON.stringify({ ...customerData, load_id: vehicle.trip_id })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      if (res.json.result) swal(res.json.message);
    });
  };
  return (
    <main className={"vehicle-request-main " + mainClass} id="cxMain">
      <section className="table-vehicle-request" style={{ overflow: "auto" }}>
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <table className="example table-striped">
            <thead>
              <tr>
                <td>
                  <label htmlFor="">S.No.</label>
                </td>
                <td>
                  <label htmlFor="">Offer Load Reference Number</label>
                </td>
                <td>
                  <label htmlFor="">Vehicle Availability Dt.</label>
                </td>
                <td>
                  <label htmlFor="">From & To</label>
                </td>
                <td>
                  <label htmlFor="">Cargo Type</label>
                </td>
                <td>
                  <label htmlFor="">Container Type</label>
                </td>
                <td>
                  <label htmlFor="">Rem. Quantity</label>
                </td>
                {/* <td>
                  <label htmlFor="">Vehicles Req.</label>
                </td> */}
                <td>
                  <label htmlFor="">Action</label>
                </td>
              </tr>
            </thead>
            <tbody>
              {vehicleRequestList &&
                vehicleRequestList.length &&
                vehicleRequestList.map((vehicle, index) => {
                  return (
                    <tr className="t-row " key={"vehicle" + index}>
                      <td>
                        <p className="data m-0 ps-2">{index + 1}</p>
                      </td>
                      <td>
                        <Link
                          to="/GoodsDetails"
                          state={vehicle}
                          className="ms-2 ViewSpecifications-link"
                        >
                          {vehicle.load_reference_no}
                        </Link>
                      </td>
                      <td>
                        <p className="data m-0">{vehicle.trip_end_date}</p>
                      </td>
                      <td>
                        <p className="data m-0">
                          {vehicle.trip_start_country +
                            " " +
                            vehicle.trip_start_city +
                            "-" +
                            vehicle.trip_end_country +
                            " " +
                            vehicle.trip_end_city}
                        </p>
                      </td>
                      <td>
                        <p className="data m-0">{vehicle.cargo_type}</p>
                      </td>
                      <td>
                        {vehicle.cargo_type == "Container" ? (
                          <p className="data m-0">
                            {vehicle.container_type}
                          </p>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                      {vehicle.cargo_type == "Container" ? (
                        <p className="data m-0">{vehicle.quantity} Quintals</p>
                      ):vehicle.cargo_type == "Vehicle" ? (
                        <p className="data m-0">{vehicle.quantity} Vehicles</p>
                      ):(<p className="data m-0">{vehicle.quantity} Quintals</p>)}
                      </td>
                      {/* <td>
                        <Link
                          onClick={() => {
                            setCurrentLoadId(vehicle.trip_id);
                            handleShow();
                          }}
                          to="#"
                          className="ms-2 ViewSpecifications-link"
                        >
                          View Details
                        </Link>
                      </td> */}
                      <td>
                        {/* <button
                          className="vehicleRequest-btn-remove"
                          onClick={() => {
                            reject(vehicle);
                          }}
                        >
                          <img src={remove} alt="" />
                        </button> */}
                        <button className="vehicleRequest-btn ms-2">
                          <Link
                            onClick={() => {
                              setCurrentLoadId(vehicle.trip_id);
                              handleShow();
                            }}
                            state={vehicle}
                            to="/TransporterAuction"
                            className="text-decoration-none"
                            style={{ color: "#4F9FE6" }}
                          >
                            Bid
                          </Link>
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}
      </section>
      <Modal
        show={show}
        onHide={handleClose}
        animation={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-content-box"
      >
        <Modal.Header closeButton></Modal.Header>
        <div>
          <h3 className=" card-heading mt-4">Truck Specifications </h3>
        </div>
        <div
          className="btn-popup-modal text-center"
          style={{ overflow: "auto" }}
        >
          <table
            className="example table-striped "
            style={{ minWidth: "auto" }}
          >
            <thead>
              <tr>
                <td>
                  <label htmlFor="">Trucks</label>
                </td>
                <td>
                  <label htmlFor="">Cargo Type</label>
                </td>
                <td>
                  <label htmlFor="">Capacity in Quintals</label>
                </td>
              </tr>
            </thead>
            <tbody>
              {vehicleList?.map((vehicle, index) => {
                return (
                  <tr className="t-row ">
                    <td>
                      <p className="data m-0 ps-2">{index + 1}</p>
                    </td>
                    <td>
                      <p className="data m-0">{vehicle.vehicle_type}</p>
                    </td>
                    <td>
                      <p className="data m-0">{vehicle.capacity}</p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Modal>
      <Modal
        className="modal-md accept-popup"
        id="myModal"
        role="dialog"
        onHide={handleCloseAccept}
        animation={true}
        show={showAccept}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>

        <div>
          <p className="text-center popup-heading mt-4">
            Are you sure you want to approve this offer?
          </p>
        </div>
        <div className="btn-popup-modal justify-content-evenly  d-flex">
          <Link to="#" className="m-1">
            <button type="button" className="popup-reject-btn ">
              Reject
            </button>
          </Link>

          <Link to="/OfferVehicles" className="m-1">
            <button type="button" className="popup-approve-btn ">
              Approve
            </button>
          </Link>
        </div>
      </Modal>
    </main>
  );
};

export default VehicleRequestOnlineAuction;
