import React from "react";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import ApiConfig from "../api/ApiConfig";
import { simplePostCall } from "../api/ApiServices";
// import "../assets/css/login.scss";
import logo from "../assets/images/Login/logo.svg";
const Login = () => {
  let ForgotData = useLocation().state;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    ...ForgotData,
    token: "",
    new_password: "",
    confirm_password: "",
  });
  const [errMsg, setErrMsg] = useState({});

  const resetPassword = (e) => {
    e.preventDefault();
    setLoading(true);

    if (state.new_password === "") {
      setErrMsg({ ...errMsg, new_password: "Enter New Password " });
      return;
    }
    if (state.confirm_password === "") {
      setErrMsg({ ...errMsg, confirm_password: "Enter New Password " });
      return;
    }
    if (state.token === "") {
      setErrMsg({ ...errMsg, token: "Enter Token " });
      return;
    } else {
      simplePostCall(ApiConfig.RESET_PASSWORD, JSON.stringify({ ...state }))
        .then((res) => {
          if (res.json.message === 
            "Invalid user authentication,Please try to relogin with exact credentials.") {
              localStorage.clear();
              window.open("/login", "_self");
          }

          if (res.result === true) {
            navigate("/");
            swal(res.message);
          } else {
            swal(res.message);
          }

          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <section className=" container-fluid login-main-section ">
      <div className="row">
        <div className="col-lg-6 col-md-6 left-section">
          <div className="left-content">
            <img src={logo} alt="" />
            <h3 className="mt-3 heading">Abay Logistics</h3>
            <p className="left-login-paragraph">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat.
            </p>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 right-section">
          <div className="right-content ">
            <h3>Reset Password</h3>

            <p className="welcome-login">
              Welcome to <span>Abay Logistics</span>
            </p>
            <form action="">
              <div className="mb-3 row">
                <label
                  htmlFor="inputEmail"
                  className="col-sm-12 col-form-label"
                >
                  New Password
                </label>
                <div className="col-sm-12">
                  <input
                    type="password"
                    className="form-control"
                    id="inputEmail"
                    onChange={(e) => {
                      setErrMsg({ ...errMsg, new_password: "" });
                      setState({ ...state, new_password: e.target.value });
                    }}
                  />
                  {errMsg.new_password && errMsg.new_password.length > 0 && (
                    <span className="text-danger">{errMsg.new_password}</span>
                  )}
                </div>
              </div>
              <div className="mb-3 row">
                <label
                  htmlFor="inputEmail"
                  className="col-sm-12 col-form-label"
                >
                  Confirm Password
                </label>
                <div className="col-sm-12">
                  <input
                    type="password"
                    className="form-control"
                    id="inputEmail"
                    onChange={(e) => {
                      setErrMsg({ ...errMsg, confirm_password: "" });
                      setState({ ...state, confirm_password: e.target.value });
                    }}
                  />
                  {errMsg.confirm_password &&
                    errMsg.confirm_password.length > 0 && (
                      <span className="text-danger">
                        {errMsg.confirm_password}
                      </span>
                    )}
                </div>
              </div>
              <div className="mb-3 row">
                <label
                  htmlFor="inputEmail"
                  className="col-sm-12 col-form-label"
                >
                  Token
                </label>
                <div className="col-sm-12">
                  <input
                    type="text"
                    maxLength="4"
                    className="form-control"
                    id="inputEmail"
                    onChange={(e) => {
                      setErrMsg({ ...errMsg, token: "" });
                      setState({ ...state, token: e.target.value });
                    }}
                  />
                  {errMsg.token && errMsg.token.length > 0 && (
                    <span className="text-danger">{errMsg.token}</span>
                  )}
                </div>
              </div>
              {/* <div className="mb-4 row">
                <label
                  htmlFor="inputPassword"
                  className="col-sm-12 col-form-label"
                >
                  Password
                </label>
                <div className="col-sm-12">
                  <input
                    type="password"
                    className="form-control"
                    id="inputPassword"
                  />
                </div>
              </div> */}
              {/* <div className="forgot">
              <a href="" >Forgot Password?</a>
              </div> */}
              <div className="login-btn ">
                <Link
                  to="#"
                  className=" col-sm-12 col-md-12 col-lg-12"
                  onClick={resetPassword}
                >
                  Submit
                </Link>
              </div>
              <div
                className="forgot pt-3"
                style={{
                  textAlign: "center",
                }}
              >
                <Link to="/AddTransporter">
                  Don't have an account?{" "}
                  <span
                    style={{
                      color: "#33778E",
                    }}
                  >
                    Register
                  </span>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
